import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import FormHeader from '../../components/FormHeader/FormHeader';
import KJSIEIT from '../../components/KJSIEIT/KJSIEIT';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
        borderRadius : theme.spacing(2)
      },
}));
const MembershipPolicy = () => {
    const classes = useStyles();
    return (
        <div>
            <FormHeader></FormHeader>
            <Paper className={classes.paper}>
                <br/>
                <h2>PRIVACY POLICY</h2>
<p>
ISTE (“we” or “us”) is the owner of the www.isteonline.in, and other associated website(s), which may be
added or removed from time to time (“Websites” and each individually a “Website”). We are committed to
your privacy. This online privacy policy discloses our practices for the Websites and subscriber based
services (“Services”) including the type of information being collected, method of such information
collection, use of such information and sharing such information with third parties. For the purposes of the
Privacy Policy, “personal information” would mean and include information that relates to a natural person,
which either directly or indirectly in combination with other information available or likely to be available to
us, is capable of identifying such person. If you have any questions or concerns regarding this privacy
policy, you should contact our Grievance Officer at istedhq@isteonline.org
We value the trust you place in us. That’s why we insist upon the highest standards for secure transactions
and customer information privacy. Please read the following statement to learn about our information
gathering and dissemination practices.
<br/>
<br/>
By visiting this Website (including all associated website(s), applications which may be added or removed
from time to time) you agree to be bound by the terms and conditions of this Privacy Policy. If you do not
agree please do not use or access our Website.
By mere use of the Website, you expressly consent to our use and disclosure of your personal information
in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of
Use.
<br/>
<br/>
<h3>Collection of personally identifiable information and other information</h3>
When you use our Website, we collect and store your personal information which is provided by you from
time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized
experience. This allows us to provide services and features that most likely meet your needs, and to
customize our Website to make your experience safer and easier. More importantly, while doing so we
collect personal information from you that we consider necessary for achieving this purpose.
We are the custodian of data on behalf of the users that use our Websites. We don’t own communication
data. Users own their data. However, by submitting the data to us, you hereby grant us non-exclusive,
royalty-free, sublicense able and transferable license to use, reproduce, distribute, prepare derivative
works of, display, in connection with our Service and ISTE’s (and its successor’s) business, including
without limitation for promoting and redistributing part or all of our Service (and derivative works thereof) in
any media formats and through any media channels. No sale, sharing, transfer or renting out of any
personal information to others in ways different from what is disclosed in this Privacy Policy, the Terms &amp;
Conditions and the Terms of Use will occur at any point of time. We collect information from you on the
“Sign Up” and “Registration” pages (“Profile Pages”) of the Websites. We reserve the rights to collect,
disseminate, sell or otherwise disclose non-personal information provided by you including but not limited
to information collected from educational institutes/schools or added/updated through other channels and
other pages on the ISTE platform including web pages and mobile application. Additionally, you may
encounter “cookies” or other similar devices on certain pages of the Website that are placed by third
parties. We do not control the use of cookies by third parties. Every computer connected to the Internet is
given a domain name and a set of numbers that serve as that computer’s Internet Protocol or IP address.
When you browse the Websites, our web servers automatically recognize your domain name and IP
address. The domain name and IP address reveal nothing personal about you other than the IP address
from which you have accessed the Website.
During the collection of information on the Profile Pages on the Websites, you are required to provide your
applicable contact information (such as name, e-mail address and the like). An email address is also used
to contact you about the materials and information on the Websites. When the Websites request for the
confirmation of your identity, the Websites will clearly indicate the purpose of the inquiry before such
information is requested.
<br/>
<br/>
If you choose to post messages on our message boards or other message areas or leave feedback, we
will collect that information you provide to us. We retain this information as necessary to resolve disputes,
provide customer support and troubleshoot problems as permitted by law. When you participate in a forum
on the Websites including, but not limited to posts, poll votes, and post comments, your name and IP
address may be recorded for purposes of maintaining your own account within the Forums, and preventing
abuses of the forums. In order to diffuse the information in the Websites’ forums to a wider audience, we
may, from time to time, collect some of your postings and group them together to use in a specific
publication, print, electronic mailing or other public dissemination. At no point however will your name or IP
address be revealed in any publication. In addition, when your postings are used in this fashion, they may
be edited to fit with the general content of the publication being prepared. It is important to remember that
whenever you voluntarily disclose personal information in a forum, through e-mail or elsewhere, that
information can be collected and used by others. If your personal information is accessible to the public,
you may receive unsolicited messages from other parties in return. You are solely responsible for
maintaining the secrecy of your personal information. You may participate in any forums offered through
the Websites, but you agree not to post any material the content of which (i) is defamatory, libelous,
obscene, indecent, abusive, threatening to others or in violation of any law; or (ii) infringes the copyright,
trademark right, or other intellectual property right of any third party. You will be solely responsible for all
content that you post on any forum available on the Websites. You agree to indemnify us and our officers
and employees from and against all liabilities, judgments, damages, and costs (including attorney’s fees)
incurred by any of them which arise out of or are related to the content that you post. Forums are intended
only for the personal use of our subscribers, and may not be used for commercial purposes or for
organized political activity.
<br/>
<br/>
If you send us personal correspondence, such as emails or letters, or if other users or third parties send us
correspondence about your activities or postings on the Website, we may collect such information into a
file specific to you.
<br/>
<br/>
<h3>Notification of modifications and changes to the terms &amp; conditions and privacy policy</h3>
We reserve the right to change the Terms and Privacy Policy from time to time as seen fit, without any
intimation to you, and your continued use of the site will signify your acceptance of any amendment to
these terms. You are therefore advised to re-read the Terms of Service on a regular basis. Should it be
that you do not accept any of the modifications or amendments to the Terms, you may terminate your use
of this website immediately. In the event there are significant changes in the way we treat your personal
information, or in the Privacy Policy, we will display a notice on the Websites or send you an email, so that
you may review the changed terms prior to continuing to use the Websites. As always, if you object to any
of the changes to our terms, and you no longer wish to use the Websites, you may contact us
at <b>istedhq@isteonline.org</b> to deactivate your account. Unless stated otherwise, our current Privacy Policy
applies to all information that we have about you and your account. Using the services on the Websites or
accessing the Websites after a notice of change has been sent to you or published on our Websites shall
constitute your consent to the changed terms.

<br/>
<br/>
We may send you Website and service announcement updates. You will not be able to unsubscribe from
service announcements, which contain important information about the Websites. We communicate via e-
mail to provide requested services and to resolve issues relating to your account.

<h3>Use of Demographic / Profile Data / Your Information</h3>
We do not collect personal information about individuals, except when individuals consent to share such
personal information on the Websites. While we take utmost care with respect to the security of the
information you decide to upload, you understand that any information that you disclose on the Website is
at your own risk. By uploading / sharing / disclosing your information on the Website, you hereby give your
consent to us to store such information on our servers. We will retain such information you upload on the
Website for as long as it is needed to fulfill the service you seek to avail on the Website. If you delete your
account, we will delete such information. But please note: (1) there might be some latency in deleting this
information from our servers and back-up storage; and (2) we may retain this information if necessary to
comply with our legal obligations, resolve disputes, or enforce our agreements.
In our efforts to continually improve our product and service offerings, we collect and analyze demographic
and profile data about our users’ activity on our Website.
We identify and use your IP address to help diagnose problems with our server, and to administer our
Website. Your IP address is also used to help identify you and to gather broad demographic information.
Information collected from you may be transferred to a third party as a result of a sale or acquisition,
merger or bankruptcy involving ISTE.
<br/>
<br/>
<h3>
Cookies
</h3>


We use our as well as third parties’ data collection devices such as “cookies” on certain pages of our
website. “Cookies” are small files stored on your hard drive that assist us in providing services customized
to your requirements and tastes. We also offer certain features that are only available through the use of a
“cookie”. Cookies can also help us provide information, which is targeted to your interests. Cookies may be
used whether you choose to register with us or not. Third party vendors such as www.google.com
(“Google”) may use cookies to serve ads based on your visits to this Website. You may visit the website of
the third party and choose to opt out of the use of cookies for interest-based advertising, if the third party
offers such an option. You may choose to opt-out of the DoubleClick cookie that Google and its partners
use for interest-based advertising by visiting Ads Settings. We also use cookies to allow you to enter your
password less frequently during a session. Cookies can also help us provide information that is targeted to
your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your
hard drive at the end of a session. You are always free to decline our cookies if your browser permits,
although in that case you may not be able to use certain features on the Website and you may be required
to re-enter your password more frequently during a session. A cookie cannot read data off your hard disk
or read cookie files created by other sites. Use of a cookie is in no way linked to any personally identifiable
information while on the Websites. Once you close your browser, the cookie simply terminates. For
instance, by setting a cookie on your browser, you would not have to log in a password more than once,
thereby saving time while on the Websites. You can choose whether to accept cookies by changing the
settings of your browser. You can reset your browser to refuse all cookies or allow your browser to show
you when a cookie is being sent. If you reject the cookies on the websites, you may still be able to use the
Websites, but it shall be limited to certain minimal functionality. The only drawback to this is that you may
be limited only to some areas of the Websites or limited to certain functions of the Websites. Some of our
business partners may use cookies on the Websites. Please note that we have no access to or control
over these cookies. We and our business partners may use cookies to better personalize the content,
banners, and promotions that you see on the Websites.
<br/>
<br/>
<h3>External links on website</h3>

The website may contain hyperlinks to other websites, content or resources. We have no control over any
websites or resources, which are provided by other companies or persons. You acknowledge and agree
that we are not responsible for the availability of any such external sites or resources, and do not endorse
any advertising, products or other materials on or available from such websites or resources.
<br/>
<br/>
You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you
as a result of the availability of those external sites or resources, or as a result of any reliance placed by
you on the completeness, accuracy or existence of any advertising, products or other materials on, or
available from, such websites or resources. These third-party service providers and third-party sites may
have their own privacy policies governing the storage and retention of your personal information that you
may be subject to. We recommend that when you enter a third-party website, you review the third party
site’s privacy policy as it relates to safeguarding your personal information. We use third-party advertising
companies to serve ads when you visit the Website. You may visit the third party websites and choose to
opt-out of the use of cookies for interest-based advertising if the third party offers such an option.
<br/>
<br/>

<h3>Our use of your information</h3>

Your contact information is also used to contact you whenever necessary. We use your IP address to help
diagnose problems with our server, and to administer our Website. Your IP address is also used to help
identify you and to gather broad demographic information. Finally, we may use your IP address to help
protect our partners and ourselves from fraud. We will continue to enhance our security procedures as
new technology becomes available. We may release your personal information to a third-party in order to
comply with a Court Order or other similar legal procedure, or when we believe in good faith that such
disclosure is necessary to comply with the law; prevent imminent physical harm or financial loss; or
investigate or take action regarding illegal activities, suspected fraud, or violations of Our Terms of
Service. We may disclose personally identifiable information to parties in compliance with our Copyright
Policy as mentioned in the Terms of Service as we in our sole discretion believe necessary or appropriate
in connection with an investigation of fraud, intellectual property infringement, piracy, or other unlawful
activity. In such events, we may disclose name, address, country, phone number, email address and
Organisation name.
<br/>
<br/>
We may share aggregated demographic information with our Holding/ Subsidiaries or with our Partners /
Associates. We shall not be liable for the transfer of any personal information resulting from loss or
distribution of data, the delineation or corruption of storage media, power failures, natural phenomena,
riots, and acts of vandalism, sabotage, terrorism or any other event beyond our control. We may partner

with third parties to provide specific services. When you sign up for these third party services, we will share
names, or other contact information that is necessary for the third party to provide these services.
<br/>
<br/>
We maintain a strict “No-Spam” policy, which means that we do not intend to sell, rent or otherwise give
your e-mail address to a third party without your consent.
<br/>
<br/>

<h3>Confidentiality</h3>
You further acknowledge that the Website may contain information which is designated confidential by us
and that you shall not disclose such information without our prior written consent. Your information is
regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do
so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party
or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the
provision of agreed services.
<br/>
<br/>
We take all necessary precautions to protect your personal information both online and off-line. It is
important for you to protect against unauthorized access to your password and to your computer. Be sure
to log off from the Websites when you are using a shared computer. We also protect your personal
information off-line. Access to your personal information is limited to employees, agents or partners and
third parties with whom we are working who we reasonably believe will need that information to provide
services to you. However, we are not responsible for the confidentiality, security or distribution of your own
personal information by our partners and third parties outside the scope of our agreement with such
partners and third parties.
<br/>
<br/>
The Websites may contain links to other third party sites. The third party sites are not necessarily under
our control. Please note that we are not responsible for the privacy practices of such third party sites. We
encourage you to be aware when you leave the Websites and to read the privacy policies of each and
every third party site that collects personal information. If you decide to access any of the third-party sites
linked to the Websites, you do this entirely at your own risk. Any links to any partner of the Websites is the
responsibility of the linking party, and we shall not be responsible for notification of any change in name or
location of any information on the Websites.
<br/>
<br/>

<h3>Our disclosure of your information</h3>
Due to the existing regulatory environment, we cannot ensure that all of your private communications and
other personally identifiable information will never be disclosed in ways not otherwise described in this
Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose
information to the government, law enforcement agencies or third parties. Under certain circumstances,
third parties may unlawfully intercept or access transmissions or private communications, or members may
abuse or misuse your information that they collect from our Website. Therefore, although we use industry
standard practices to protect your privacy, we do not promise, and you should not expect, that your
personally identifiable information or private communications would always remain private.
<br/>
<br/>

As a matter of policy, we do not sell or rent any personally identifiable information about you to any third
party. However, the following describes some of the ways that your personally identifiable information may
be disclosed.
<br/>
<br/>

<b>External Service Providers</b>: There may be a number of services offered by external service providers that
help you use our Website. If you choose to use these optional services, and in the course of doing so,
disclose information to the external service providers, and/or grant them permission to collect information
about you, then their use of your information is governed by their private policy.
<br/>
<b>Other Corporate Entities</b>: We share much of our data, including personally identifiable information about
you, with our parent and/or subsidiaries and also our associates and partners that are committed to
serving your online needs and related services, throughout the world. Such data will be shared for the sole
purpose of enhancing your browsing experience. To the extent that these entities have access to your
information, they will treat it at least as protectively as they treat information they obtain from their other
members. It is possible that we and/or its subsidiaries, or any combination of such, could merge with or be
acquired by another business entity. Should such a combination occur, you should expect that we would
share some or all of your information in order to continue to provide the service.
<br/>
<b>Law and Order</b>: We cooperate with law enforcement inquiries, as well as other third parties to enforce
laws, such as: intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose
any information about you to law enforcement and other government officials as we, in our sole discretion,

believe necessary or appropriate, in connection with an investigation of fraud, intellectual property
infringements, or other activity that is illegal or may expose us or you to legal liability.


<br/>
<br/>

<h3>Accessing, reviewing and changing your profile</h3>
If your personal information changes, or if you no longer desire to use the Websites, you can update your
profile on the profile pages of the Websites. We will endeavor to correct, update or remove your personal
data provided to us.
<br/>
<br/>

<h3>Other information collectors</h3>
Except as otherwise expressly mentioned in this Privacy Policy, this document only addresses the use and
disclosure of information we collect from you. To the extent that you disclose your information to other
parties, whether they are on our Websites or on other sites throughout the internet, different rules may
apply to their use or disclosure of the information you disclose to them. To the extent that we use third
party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies of
the third parties, you are subject to ask questions before you disclose your personal information to others.
Any information that you make publicly available on the site may be potentially viewed by any party, and
by posting such material it is deemed that you consent to share such information with such parties.
<br/>
<br/>

<h3>Security</h3>

We treat data as an asset that must be protected against loss and unauthorized access. We employ many
different security techniques to protect such data from unauthorized access by members inside and
outside the Society. We do not recommend transfer of sensitive information (such as credit card number)
and bank account details via the website to any third party. Users are recommended to do so offline, on
the phone or via personal emails. We follow generally accepted industry standards to protect the personal
information submitted to us, both during transmission and once we receive it. However, “perfect security”
does not exist on the Internet. You therefore agree that any security breaches beyond the control of our
standard security procedures are at your sole risk and discretion.
<br/>
<br/>

<h3>Your consent</h3>
By using the Website and/ or by providing your information, you consent to the collection and use of the
information you disclose on the Website in accordance with this Privacy Policy, including but not limited to
your consent for sharing your information as per this privacy policy.
<br/>
If we decide to change our privacy policy, we will post those changes on this page so that you are always
aware of what information we collect, how we use it, and under what circumstances we disclose it. The
Terms and Conditions are incorporated herein by reference in its entirety.
<br/>
<br/>
<h3>Disclaimer</h3>
We cannot ensure that all of your private communications and other personal information will never be
disclosed in ways not otherwise described in this Privacy Policy. Therefore, although we are committed to
protecting your privacy, we do not promise, and you should not expect, that your personal information will
always remain private. As a user of the Site, you understand and agree that you assume all responsibility
and risk for your use of the website, the internet generally, and the documents you post or access and for
your conduct on and off the Site.
<br/>
<br/>

ABOUT US
We provide a digital platform for selling both products and services and identify and create communities
with common needs and enable services large scale ecommerce within those communities on a single
platform.
<br/>
<br/>
1. MEMBERSHIP
Use of this Website/Application is only available to those above the age of 18, excluding those
“Incompetent to Contract”, and inter-alia include insolvents. If you are a minor and wish to use the
Website, you may do so through your legal guardian and the Website/Application reserves the right to
cancel your transaction on knowledge of you being a minor and having registered on the Application or
availing any of its services without a legal guardian.
<br/>
<br/>

2. YOUR REGISTRATION OBLIGATIONS

If you use the Website/Application, You shall be responsible for maintaining the confidentiality of your
Display Name and Password and you shall be responsible for all activities that occur under your Display
Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current
or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not
current or incomplete, or not in accordance with the Terms of Use, We shall have the right to indefinitely
suspend or terminate or block access of your membership on the Website/application and refuse to
provide You with access to the Website.
<br/>
<br/>
3. COMMUNICATIONS
You are required to provide ISTE with your e-mail and/or mobile number, login/ password while transacting
through this Website/Application. By using this Website/Application, it is deemed that you have consented
to receiving calls, autodialed and/or pre-recorded message calls, from us at any time with the use of the
telephone that has been provided by you for the use of this website which are subject to the Privacy
Policy. This includes contacting you through information received through other parties. This consent to be
contacted is for purposes that includes and are not limited to clarification calls and marketing and
promotional calls. In case you wish to stop contact from us for the same, you may send us a mail to the
effect at istedhq@isteonline.org with the subject line as Remove you may also be contacted by Service
Providers with whom we have entered into a contract in furtherance of our rights, duties and obligations
under this document and all other policies followed by us. Such contact will be made only in pursuance of
such objectives, and no other calls will be made. The sharing of the information provided by you will be
governed by the Privacy Policy and we will not give out such contact information of yours to third parties
not connected with the Website.
<br/>
<br/>
4. PLATFORM FOR TRANSACTION AND COMMUNICATION
The Website/Application is a platform that Users utilize to meet and interact with one another for their
transactions and availing services. ISTE is not and cannot be a party to or control in any manner any
transaction between the Website’s Users.
<br/>
<br/>
5. DISPUTE RESOLUTION
The formation, interpretation and performance of this Agreement and any disputes arising out of it shall be
governed by the law, rules and regulations of India. The exclusive jurisdiction and venue for actions and
disputes mentioned above shall be the courts located in Delhi/New Delhi, and you hereby submit to the
personal jurisdiction of such courts.
<br/>
<br/>
6. COVENANTS
All commercial/contractual terms are offered by and agreed to between you and ISTE. The
commercial/contractual terms include without limitation price, shipping costs, payment methods, payment
terms, date, period and mode of transaction, warranties related to products and services and after sales
services related to products and services.
<br/>
ISTE does not make any representation or warranty as to the item-specifics (such as legal title,
creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of
any particular User that you choose to deal with on the Website and use your best judgement in that
behalf.
<br/>
The Website/Application is only a platform that can be utilized by Users to reach a larger base to buy and
sell products or services. ISTE is only providing a platform for goods and services.
You shall agree upon the manner and terms and conditions of payment, insurance etc. Disclaimer: Pricing
on any product(s)/Service(s) as is reflected on the Website/Application may due to some technical issue,
typographical error or product/service information published may be incorrectly reflected and in such an
event ISTE may cancel such your order(s).
<br/>
You release and indemnify ISTE and/or any of its officers and representatives from any cost, damage,
liability or other consequence of any of the actions of the Users of the Website and specifically waive any
claims that you may have in this behalf under any applicable law.
<br/>
7. CHARGES &amp; REFUND POLICY
ISTE reserves the right to change its Fee Policy from time to time. Changes to the Fee Policy shall be
posted on the Website and such changes shall automatically become effective immediately after they are
posted on the Website. Unless otherwise stated, all fees shall be quoted in Indian Rupees.
<br/>
<ul>
    <li>Membership will be issued after making successful payment. No refund shall be made after
payment.</li>
<li>In case of any revision of fee/GST etc. between date of application and date of payment, the
revised fee shall be applicable.</li>
</ul>
8. USER OBLIGATIONS
You are a restricted user of this website. You are bound not to cut, copy, distribute, modify, recreate,
reverse engineer, distribute, disseminate post, publish or create derivative works from, transfer, or sell any
information or software obtained from the website/Application. With our prior permission limited use may
be allowed. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the
content for commercial or non-commercial purposes and unwarranted modification of data and information
within the content of the Website/Application is not permitted.
<br/>
<br/>
<b>8.1</b> You agree not to access (or attempt to access) the Website and/or the materials or Services by any
means other than through the interface that is provided by the website. The use of deep-link, robot, spider
or other automatic device, program, algorithm or methodology, or any similar or equivalent manual
process, to access, acquire, copy or monitor any portion of the Website/Application or Content, or in any
way reproduce or circumvent the navigational structure or presentation of the Website, materials or any
Content, to obtain or attempt to obtain any materials, documents or information through any means not
specifically made available through the Website/Application is specifically banned. You acknowledge and
agree that by accessing or using the Website/Application or Services, You may be exposed to content
from other users that you may consider offensive, indecent or otherwise objectionable. We disclaim all
liabilities arising in relation to such offensive content on the Website/Application. Further, you may report
such allegedly offensive content to istedhq@isteonline.org
<br/>
<br/>
<b>8.2</b> In places where this website/Application allows you to post or upload data/information, you undertake
to ensure that such material is not offensive and in accordance with applicable laws. Further, you
undertake not to:
<ol type="a">
    <li>
    Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal
rights of others;
    </li>
    <li>
    Engage in any activity that interferes with or disrupts access to the Website or the Services (or the
servers and networks which are connected to the Website);
    </li>
    <li>
    Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a
person or entity;
    </li>
    <li>
    Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous,
defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or
racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or
otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but
not limited to “indecent representation of women”.
    </li>
    <li>
    Post any file that infringes the copyright, industrial design, patent, trademark or any other intellectual
property of other legal entities.
    </li>
    <li>
    Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs
that may damage the operation of the Website/Application or another’s computer;
    </li>
    <li>
    Probe, scan or test the vulnerability of the Website or any network connected to the Website, or breach
the security or authentication measures on the Website or any network connected to the Website. You
may not reverse look-up, trace or seek to trace any information on any other user of or visitor to, the
Website/Application, or any other customer of the website/Application, including any website/Application
Account not owned by You, to its source; or exploit the Website/Application or Service or information made
available or offered by or through the Website, in any manner, whether or not the purpose is to reveal any
information, including but not limited to personal identification information, other than Your own
information, as provided for by the Website;
    </li>
    <li>
    Disrupt or interfere with the security of, or otherwise cause harm to, the Website/Application, systems
resources, accounts, passwords, servers or networks connected to or accessible through the Websites or
any affiliated or linked sites;
    </li>
    <li>
    Collect or store data about other users in connection with the prohibited conduct and activities set forth
in this Section.
    </li>
    <li>
    Use the Website/Application or any material or Content for any purpose that is unlawful or prohibited by
these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the
rights of this website or other third parties;
    </li>
    <li>
    Violate any code of conduct or other guidelines, which may be applicable for or to any particular
Service;
    </li>
    <li>
    Violate any applicable laws or regulations for the time being in force within or outside India;
    </li>
    <li>
    Violate the Terms of Use including but not limited to any applicable Additional Terms of the Website
contained herein or elsewhere;
    </li>
    <li>
    Create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service
provider (“ISPs”) or other suppliers;
    </li>
    <li>
    Multiple accounts for the same purpose are not allowed on the website/application. Result of such
activity is all irrevocable account suspension.
    </li>
    <li>
    Our payment methods can be changed anytime without any notification. You shall not engage in
advertising to, or solicitation of, other Users of the Website to buy or sell any products or services,
including, but not limited to, products or services related to that being displayed on the Website or related
to us. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the
Website. It shall be a violation of these Terms of Use to use any information obtained from the Website in
order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to
another person without our prior explicit consent. We can (and You hereby expressly authorize Us to)
disclose any information about You to law enforcement or other government officials, as we, in Our sole
discretion, believe necessary or appropriate in connection with the investigation and/or resolution of
possible crimes, especially those that may involve personal injury. We shall have the right to remove or
edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the
spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE
FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE AND IN YOUR PRIVATE
MESSAGES. In no event shall ISTE assume or have any responsibility or liability for any Content posted
or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the
Website. You hereby represent and warrant that you have all necessary rights in and to all Content which
you provide and all information it contains and that such Content shall not infringe any proprietary or other
rights of third parties or contain any libelous, tortuous, or otherwise unlawful information.
    </li>
</ol>
<br/>
<br/>

<b>8.3</b> ISTE holds no responsibility for any content posted by any user or third party. For any content/s posted
by anyone on the website, that party shall solely be liable for such content/s.
Except as expressly provided in these Terms of Use, no part of the Website and no Content may be
copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or
distributed in any way (including “mirroring”) to any other computer, server, Website or other medium for
publication or distribution or for any commercial enterprise, without ISTE’s express prior written consent.
You may use information on the products and services purposely made available on the Website for
downloading, provided that You (1) do not remove any proprietary notice language in all copies of such
documents, ( 2) use such information only for your personal, non-commercial informational purpose and
do not copy or post such information on any networked computer or broadcast it in any media, (3) make no
modifications to any such information, and (4) do not make any additional representations or warranties
relating to such documents.
You shall be responsible for any notes, messages, emails, billboard postings, photos, drawings, profiles,
opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the
Website (collectively, “Content”). Such Content will become our property and you grant us the worldwide,
perpetual and transferable rights in such Content. We shall be entitled to, consistent with Our Privacy
Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of
use forever, including but not limited to promotional and advertising purposes and in any media whether
now known or hereafter devised, including the creation of derivative works that may include the Content
You provide. You agree that any Content You post may be used by us, consistent with Our Privacy Policy
and Rules of Conduct on Site as mentioned herein, and you are not entitled to any payment or other
compensation for such use.
<br/>
<br/>
<b>8.4</b> We view protection of your privacy as a very important principle. We understand clearly that You and
Your Personal Information is one of our most important assets. We store and process Your Information
including any sensitive personal information collected (as defined under the Information Technology Act,
2000), if any, on computers that may be protected by physical as well as reasonable technological security
measures and procedures in accordance with Information Technology Act 2000 and Rules thereunder. If
you object to Your Information being transferred or used in this way please do not use this Website.
We may share personal information with our other corporate entities and affiliates. These entities and
affiliates may market to you as a result of such sharing unless you explicitly opt-out.
We may disclose personal information to third parties. This disclosure may be required for us to provide
you access to our services, to comply with our legal obligations, to enforce our User Agreement, to
facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent
or illegal activities related to our Services. We do not disclose your personal information to third parties for
their marketing and advertising purposes without your explicit consent.
We may disclose personal information if required to do so by law or in the good faith belief that such
disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may
disclose personal information to law enforcement offices, third party rights owners, or others in the good
faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy;
responding to claims that an advertisement, posting or other content violates the rights of a third party; or
protect the rights, property or personal safety of our users or the general public. We and our affiliates will
share some of your personal information with another business entity should we (or our assets) plan to
merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of
business. Should such a transaction occur that other business entity (or the new combined entity) will be
required to follow this privacy policy with respect to your personal information.
<br/>
<br/>
<b>8.5</b>  This Website, all the materials and products (including but not limited to software) and services,
included on or otherwise made available to you through this site are provided on “as is” and “as available”
basis without any representation or warranties, express or implied except otherwise specified in writing.
Without prejudice to the forgoing paragraph, ISTE does not warrant that:
<ul>
    <li>This Website will be constantly available, or available at all; or</li>
    <li>The information on this Website is complete, true, accurate or non- misleading.</li>
</ul>
ISTE will not be liable to you in any way or in relation to the Contents of, or use of, or otherwise in
connection with, the Website. ISTE does not warrant that this site; information, Content, materials, product
(including software) or services included on or otherwise made available to You through the Website; their
servers; or electronic communication sent from Us are free of viruses or other harmful components.
Nothing on Website constitutes, or is meant to constitute, advice of any kind. All the Products sold/services
provided through this Website are governed by different state laws and if Seller/service provider is unable
to provide such Products/services due to implications of different state laws, Seller/service provider will
return or will give credit for the amount (if any) received in advance in for the transaction. You will be
required to enter a valid phone number while connecting with other users on the Website. By registering
your phone number with us, you consent to be contacted by us via phone calls and/or SMS notifications/
or any other mode, in case of any order or shipment related updates.
<br/>
<br/>

<b>8.6</b>  While availing any of the payment method/s available on the Website, we will not be responsible or
assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due
to:
<ul>
    <li>
    Lack of authorization for any transaction/s, or
    </li>
    <li>
    Exceeding the preset limit mutually agreed by you and between “Bank/s”, or
    </li>
    <li>
    Any payment issues arising out of the transaction, or
    </li>
    <li>
    Decline of transaction for any other reason/s All payments made against the purchases/services on Website
by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. Website will not facilitate
transaction with respect to any other form of currency with respect to the purchases made on Website.

    </li>
    Further
    <li>
    Transactions, Transaction Price and all commercial terms such as Delivery, Dispatch of products and/or
services are as per principal to principal bipartite contractual obligations between Buyer and Seller and
payment facility is merely used by the Buyer and Seller to facilitate the completion of the Transaction. Use of

the payment facility shall not render ISTE liable or responsible for the non-delivery, non-receipt, non-
payment, damage, breach of representations and warranties, non-provision of after sales or warranty
services or fraud as regards the products and /or services listed on the Website.
    </li>
    <li>
    You have specifically authorized ISTE or its service providers to collect, process, facilitate and remit
payments and/or the Transaction Price electronically in respect of transactions through Payment Facility.
    </li>
    <li>
    You understand, accept and agree that the payment facility provided by ISTE is neither a banking nor
financial service but is merely a facilitator providing an electronic, automated online electronic payment,
collection and remittance facility for the Transactions on the ISTE Website using the existing authorized
banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment Facility,
ISTE is neither acting as trustees nor acting in a fiduciary capacity with respect to the Transaction or the
Transaction Price.
    </li>
</ul>
<br/>
<br/>
<b>8.7</b> You understand that upon initiating a Transaction You are entering into a legally binding and
enforceable contract with the other User to purchase the products and/or services from the Seller using the
Payment Facility, and You shall pay the Transaction Price through Your issuing Bank to the Seller/service
provider using Payment Facility.
<br/>
<br/>
<b>9. DATA PROVIDED BY US</b>
<br/>
<br/>
<b>9.1</b>From time to time, our team may post comments or articles. These articles are the Intellectual Property
of the Society and you are prohibited from posting, re producing, publishing, changing, editing such
articles. In case that you have a problem with any article so posted, you may report the same via mail and
we will look into it. We are under no obligation to remove such articles and the final decision lies with us.
<br/>
<br/>
<b>9.2</b>We take utmost care to ensure that data provided at our Website is true and accurate. Sometimes,
system errors creep in and data generated by other Users may be false. We are not responsible for any
such data.
<br/>
<br/>
<b>9.3</b>We are not in any way liable to you, for any loss arising to you from activities that include but are not
limited to our negligence, breach of contract, Intellectual Property and Trademark infringement, breach of
laws, etc.
<br/>
<br/>
<b>9.4</b> From time to time, our Website will have external links connected to other websites. We in no way
endorse these websites. We are in no way liable for the information found on those websites. External
websites may have their own policies and we in no way are responsible for any loss or damage cause by
the same to you. You are advised to use your own risk.
<br/>
<br/>
<b>10.</b> TRADEMARK, COPYRIGHT AND RESTRICTION
This site is controlled and operated by ISTE. All material on this site, including images, illustrations, audio
clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights.
Material on Website is solely for your personal, non-commercial use. You must not copy, reproduce,
republish, upload, post, transmit or distribute such material in any way, including by email or other
electronic means and whether directly or indirectly and you must not assist any other person to do so.
Without the prior written consent of the owner, modification of the materials, use of the materials on any
other website or networked computer environment or use of the materials for any purpose other than
personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and
is prohibited. Any use for which you receive any remuneration, whether in money or otherwise, is a
commercial use for the purposes of this clause.
<br/>
<br/>
<b>11.</b>  INDEMNITY
You agree to defend, indemnify, and hold harmless ISTE, its officers, subsidiaries, affiliates, successors,
assigns, directors, officers, agents, service providers, suppliers, and employees from and against any and
all claims, damages, obligations, losses, liabilities, costs, or debt and expenses arising from-
 Your use of and access to the website and/or the service;
 Your violation of any term of these Terms;
 Your violation of any third party right, including without limitation any copyright, trademark, trade secret, or
other property, or privacy right; or
 Any claim that your content caused damage to a third party.
This defense and indemnification obligation shall survive termination, modification or expiration of these
Terms and your use of the service and the website.
<br/>
<br/>
<b>12.</b> TERMINATION AND LIMITATION OF SERVICE
You acknowledge and agree that ISTE may establish limits from time to time concerning use of the
service, including among others, the maximum number of days that content will be maintained or retained
by the service, the maximum number and size of postings, email messages, or other content that may be
transmitted or stored by the service, and the frequency with which you may access the service or the
website. You acknowledge and agree that ISTE has no responsibility or liability for the deletion or failure to
store any content maintained or transmitted by the website or the service. You acknowledge and agree
that ISTE in its sole and absolute discretion, has the right (but not an obligation) to delete, or deactivate
your account, block your email or IP address, or otherwise terminate your access to or use of the services
Immediately and without notice and remove and discard any content within the service, for any reason or
no reason at all, including without limitation, if ISTE believes that you have violated these Terms.
<br/>
<br/>
<b>13. </b>GRIEVANCE OFFICER
If you have any grievance with respect to the Website or the service, you can contact our grievance officer at:
<br/>
Executive Secretary<br/>
ISTE New Delhi<br/>
Email: istedhq@isteonline.org<br/>

</p>
            </Paper>
            <KJSIEIT></KJSIEIT>
        </div>
    )
}

export default MembershipPolicy
