import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useStyles from "./styles";

const NewInputForm = ({
  handleSubmit,
  handleChange,
  membershipCategory,
  genderCategory,
  initials,
  formData,
  formError,
  highestQualificationCertificate,
  handleHighestQualificationUpload,
  handleImageUpload,
  branch,
  designation,
  file,
  image,
  stateData,
  districtsData,
  setFormData,
  handleCertificateOfRecommendationUpload,
  handleSignatureUpload,
  signature,
  certificateOfRecommendation,
  setTermsAndConditions,
  termsAndConditions,
  sections,
  institutes,
}) => {
  const classes = useStyles();
  const [qualifications, setQualifications] = useState("");
  return (
    <>
      <Typography className={classes.formTitle} component="h5" variant="h5">
        Add Lifetime Member
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography>Personal Details</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} align="center">
            {image && (
              <>
                <img
                  src={URL.createObjectURL(image)}
                  width="20%"
                  alt="Profile Image"
                />
                <Typography variant="body1">{image?.name}</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={2} xl={2}>
            <TextField
              required
              error={formError.initials}
              name="initials"
              label="Initials"
              value={formData.initials}
              fullWidth
              variant="outlined"
              autoFocus
              onChange={handleChange}
              select
            >
              {initials.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.firstName}
              helperText={formError.firstName && "Invalid Entry"}
              required
              name="firstName"
              label="First Name"
              value={formData.firstName}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <TextField
              error={formError.middleName}
              helperText={formError.middleName && "Invalid Entry"}
              name="middleName"
              label="Middle Name"
              value={formData.middleName}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <TextField
              error={formError.lastName}
              helperText={formError.lastName && "Invalid Entry"}
              // required
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Date of Birth"
              format="dd/MM/yyyy"
              name="startDate"
              value={formData.dateOfBirth || new Date()}
              error={formError.dateOfBirth}
              helperText={formError.dateOfBirth && "Invalid Date"}
              InputAdornmentProps={{ position: "start" }}
              onChange={(date) => {
                setFormData({ ...formData, dateOfBirth: date });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={2} xl={2}>
            <TextField
              error={formError.gender}
              helperText={formError.gender && "Invalid Entry"}
              required
              name="gender"
              label="Gender"
              value={formData.gender || ""}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              select
            >
              {genderCategory.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.email}
              helperText={formError.email && "Invalid Entry"}
              name="email"
              label="Email"
              value={formData.email}
              type="email"
              fullWidth
              required
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <TextField
              // error={formError.email}
              // helperText={formError.alternateEmail && 'Invalid Entry'}

              name="alternateEmail"
              label="Alternate Email"
              value={formData.alternateEmail}
              type="email"
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.mobileNo}
              helperText={formError.mobileNo && "Invalid Entry"}
              name="mobileNo"
              label="Mobile Phone Number"
              value={formData.mobileNo}
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.landlineTelephone}
              helperText={formError.landlineTelephone && "Invalid Entry"}
              name="landlineTelephone"
              label="Alternate Contact Number"
              value={formData.landlineTelephone}
              type="number"
              fullWidth
              onChange={handleChange}
              variant="outlined"
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.panNo}
              helperText={formError.panNo && "Invalid Entry"}
              name="panNo"
              label="Pancard Number"
              value={formData.panNo}
              fullWidth
              onChange={handleChange}
              variant="outlined"
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              // required
              error={formError.aadharNo}
              helperText={formError.panNo && "Invalid Entry"}
              name="aadharNo"
              label="Aadhar Number"
              fullWidth
              variant="outlined"
              value={formData.aadharNo}
              onChange={handleChange}
            ></TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <input
              className={classes.uploadButton}
              id="contained-button-file1"
              name="imageUpload"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-file1">
              <Box display="flex">
                <Button variant="outlined" component="span">
                  <BackupIcon className={classes.buttonIcon} /> Upload Image
                </Button>
                <Typography>&nbsp;&nbsp;{image?.name} </Typography>
              </Box>
            </label>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <input
              className={classes.uploadButton}
              id="contained-button-file4"
              name="signatureUpload"
              type="file"
              onChange={handleSignatureUpload}
            />
            <label htmlFor="contained-button-file4">
              <Box display="flex">
                <Button variant="outlined" component="span">
                  <BackupIcon className={classes.buttonIcon} /> Upload Signature
                </Button>
                <Typography>&nbsp;&nbsp;{signature?.name} </Typography>
              </Box>
            </label>
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography>Professional Details</Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              required
              error={formError.branch}
              name="branch"
              label="Branch"
              value={formData.branch}
              fullWidth
              variant="outlined"
              autoFocus
              onChange={handleChange}
              select
            >
              {branch.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.areaOfSpecialisation}
              helperText={formError.areaOfSpecialisation && 'Invalid Entry'}
              required={formData.branch === "OTHERS (PLEASE SPECIFY)"}
              name="areaOfSpecialisation"
              label="Others (Please Specify)"
              value={formData.areaOfSpecialisation}
              // rows = "3"
              fullWidth
              onChange={handleChange}
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.designation}
              required
              name="designation"
              label="Designation"
              value={formData.designation}
              fullWidth
              variant="outlined"
              autoFocus
              onChange={handleChange}
              select
            >
              {designation.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.other_designation}
              helperText={formError.other_designation && 'Invalid Entry'}
              required={formData.designation === 'Others'}
              name="other_designation"
              label="Others (Please Specify)"
              value={formData.other_designation}
              // rows = "3"
              fullWidth
              onChange={handleChange}
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              error={formError.chapter_code}
              helperText={formError.chapter_code && "Invalid Entry"}
              name="chapter_code"
              label="Chapter Code"
              value={formData.chapter_code}
              onChange={handleChange}
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="institute-native-simple">
                Institute Name
              </InputLabel>
              <Select
                native
                error={formError.institute}
                helperText={formError.institute && "Invalid Entry"}
                value={formData.institute}
                onChange={handleChange}
                label="Institute Name"
                fullWidth
                inputProps={{
                  name: "institute",
                  id: "institute-native-simple",
                }}
              >
                {institutes.map((value, idx) => {
                  return <option value={value}>{value}</option>;
                })}
                <option value="other_institute">Other</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.othersInstitute}
              helperText={formError.othersInstitute && "Invalid Entry"}
              required
              name="otherInstitute"
              label="Other (Please Specify Institute Name)"
              value={formData.othersInstitute}
              // rows = "3"
              fullWidth
              onChange={handleChange}
              multiline
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Educational Qualification
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="qualifications"
                onChange={(e) => {
                  if (e.target.value !== "Others") {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    });
                  } else {
                    setFormData({
                      ...formData,
                      [e.target.name]: qualifications,
                    });
                  }
                }}
              >
                <div>
                  {[
                    "BE. / B.Tech / BS. / MS.",
                    "ME. / M.Tech / M. Arch. / M. Planning",
                    "MBA / MCA",
                    "B. Pharma / M. Pharma",
                    "PHD (Science / Engg. &amp; Tech./ Pharma/ Arch./Mgmt/Plan.",
                    "B. Arch. / B. HMCT / B. Planning",
                    "Diploma in Engg. And Technology (Polytechnic)",
                    "Others",
                  ].map((value) => {
                    if (value === "Others") {
                      return (
                        <>
                          <FormControlLabel
                            value={value}
                            control={<Radio />}
                            label={"Others (Please Specify)"}
                          />
                          <TextField
                            size="small"
                            label="Please Specify"
                            variant="outlined"
                            name="qualifications"
                            onChange={(e) => {
                              setQualifications(e.target.value);
                              const isSelected = [
                                "BE. / B.Tech / BS. / MS.",
                                "ME. / M.Tech / M. Arch. / M. Planning",
                                "MBA / MCA",
                                "B. Pharma / M. Pharma",
                                "PHD (Science / Engg. &amp; Tech./ Pharma/ Arch./Mgmt/Plan.",
                                "B. Arch. / B. HMCT / B. Planning",
                                "Diploma in Engg. And Technology (Polytechnic)",
                              ].includes(formData.qualifications);
                              if (!isSelected) {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }
                            }}
                          ></TextField>
                        </>
                      );
                    }
                    return (
                      <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    );
                  })}
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.city}
              helperText={formError.city && "Invalid Entry"}
              required
              name="city"
              label="Name of City"
              value={formData.city}
              fullWidth
              onChange={handleChange}
              // multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              required
              name="state"
              error={formError.state}
              helperText={formError.state && "Invalid Entry"}
              label="Name of State"
              value={formData.state || ""}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              select
            >
              {stateData.map((option) => (
                <MenuItem key={option._id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              required
              name="section"
              error={formError.section}
              helperText={formError.section && "Invalid Entry"}
              label="Section"
              value={formData.section || ""}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              select
            >
              {sections.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.section_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              // required
              name="district"
              error={formError.district}
              helperText={formError.district && "Invalid Entry"}
              label="Name of District"
              value={formData.district || ""}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              select
            >
              {districtsData
                .filter(
                  (value) =>
                    !formData.state || formData.state === value?.state?.name
                )
                .map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} lg={2} xl={2}>
            <TextField
              error={formError.pincode}
              helperText={formError.pincode && "Invalid Entry"}
              required
              name="pincode"
              label="Pincode"
              value={formData.pincode}
              type="number"
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <input
              className={classes.uploadButton}
              id="contained-button-file"
              name="highestQualificationCertificate"
              type="file"
              onChange={handleHighestQualificationUpload}
            />
            <label htmlFor="contained-button-file">
              <Box display="flex">
                <Button variant="outlined" component="span">
                  <BackupIcon className={classes.buttonIcon} />
                  Upload Highest Qualification Certificate
                </Button>
                <Typography>&nbsp;&nbsp;{file?.name} </Typography>
              </Box>
            </label>
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography>
              Mailing Address of Institute (Residential Address can be provided
              only for retired / non-working candidates)
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              error={formError.mailingAddress}
              helperText={formError.mailingAddress && "Invalid Entry"}
              name="mailingAddress"
              label="Mailing Address"
              value={formData.mailingAddress}
              rows="3"
              fullWidth
              onChange={handleChange}
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <TextField
              error={formError.pincodeOfMailingAddress}
              helperText={formError.pincodeOfMailingAddress && "Invalid Entry"}
              name="pincodeOfMailingAddress"
              label="Mailing Address - Pincode"
              value={formData.pincodeOfMailingAddress}
              type="number"
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Date of Application"
              format="dd/MM/yyyy"
              name="applicationDate"
              value={formData.applicationDate}
              error={formError.applicationDate}
              helperText={formError.applicationDate && "Invalid Date"}
              InputAdornmentProps={{ position: "start" }}
              onChange={(date) => {
                setFormData({ ...formData, applicationDate: date });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Typography>Recommenders Details</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.recommendersFirstName}
              helperText={formError.recommendersFirstName && "Invalid Entry"}
              // required
              name="recommendersFirstName"
              label="First Name"
              value={formData.recommendersFirstName}
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.recommendersLastName}
              helperText={formError.recommendersLastName && "Invalid Entry"}
              // required
              name="recommendersLastName"
              label="Last Name"
              value={formData.recommendersLastName}
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.recommendersEmail}
              helperText={formError.recommendersEmail && "Invalid Entry"}
              // required
              name="recommendersEmail"
              label="Email"
              value={formData.recommendersEmail}
              type="email"
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <TextField
              error={formError.recommendersMembershipNo}
              helperText={formError.recommendersMembershipNo && "Invalid Entry"}
              // required
              name="recommendersMembershipNo"
              label="Membership No. "
              value={formData.recommendersMembershipNo}
              fullWidth
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={12} align="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
            >
              Add Member
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default NewInputForm;
