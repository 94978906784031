  
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import istelogo2 from "../../assets/images/istelogo2.png";
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
    padding : theme.spacing(4),
    color : "#010057"
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

export default function FormHeader(props) {
  const classes = useStyles();
  const { sections, title } = props;

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        
        <Typography
          component="h3"
          variant="h4"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
            <div>
                <img width="100" alt="" src={istelogo2} />
            </div>
            <Hidden smDown>
              <b>INDIAN SOCIETY FOR TECHNICAL EDUCATION</b>
            </Hidden>
        </Typography>
      </Toolbar>
    </React.Fragment>
  );
}

FormHeader.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};