import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import KJSIEIT from "../../assets/images/kjsieit.gif";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import Link from '@material-ui/core/Link';

const styles = {
  media:{
    height: 0,
    paddingTop: '100%', // 16:9,
    marginTop:'30'
  }
  
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    scrollBehavior: "smooth",
  },
  heroContent: {
    backgroundImage: `url(${KJSIEIT})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(6),
  },
  cardGrid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const mentorCards = [
  {
    name: "Dr. Suresh Ukarande",
    description1: ` Principal,`,
    description2: `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    description3: "Associate Dean, \n Faculty of Science and Technology, University of Mumbai",
    description4: `National Executive Council Member, \r\n
    Indian Society for Technical Education, New Delhi`,
    image: "assets/images/PrincipalSir.png",
    linkedin: "https://www.linkedin.com/in/dr-suresh-ukarande/",
    email:"mailto:principal.tech@somaiya.edu",
    email2:"mailto:deanfotuom@gmail.com"
    
  },
  {
    name: "Dr. Radhika Kotecha",
    description1 : `Head - Department of Information Technology, `,
    description2: "K. J. Somaiya Institute Of Technology, Sion, Mumbai",
    description3: "Innovation Ambassador, MoE’s Innovation Cell",
    image: "assets/images/DrRadhikaKotecha.jpg",
    linkedin: "https://www.linkedin.com/in/dr-radhika-kotecha/",
    email:"mailto:radhika.kotecha@somaiya.edu",
    email2:"mailto:kotecha.radhika7@gmail.com"
  },
]

const projectLeads = [
  
]

const cards = [
  {
    name: "Mr. Sachin Pal",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/SachinPal.jpg",
    linkedin : "https://www.linkedin.com/in/sachin-pal89/",
    email: "mailto:sachinpal01012003@gmail.com"
  },
  {
    name: "Mr. Sanchit Warang",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/SanchitWarang.JPG",
    linkedin : "https://www.linkedin.com/in/sanchit-warang-09b0a5270/",
    email: "mailto:warangsanchit@gmail.com"
  },
  {
    name: "Mr. Parth Maniar",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/ParthManiar.jpeg",
    linkedin : "https://www.linkedin.com/in/parthdmaniar/",
    email: "mailto:officialparthmaniar@gmail.com",
    email2:"mailto:parth@buffalo.edu"
  },
  {
    name: "Ms. Gitika Jha",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/GitikaJhaPic.jpeg",
    linkedin : "https://www.linkedin.com/in/gitika-jha/",
    email: "mailto:gitikavjha@gmail.com"
  },
  {
    name: "Mr. Keshav Mishra",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/KeshavMishraPic.jpeg",
    linkedin : "https://www.linkedin.com/in/keshav-mishra-912728173/",
    email: "mailto:mishrakeshav2001@gmail.com"
  },
  {
    name: "Mr. Amit Mandaliya",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/amitMandaliyaPic.jpeg",
    linkedin : "https://www.linkedin.com/in/amit-mandaliya-7a8564202/",
    email: "mailto:amit.mandaliya29@gmail.com"
  },
  {
    name: "Mr. Mayuresh Nivatkar",
    description1: `Alumni - Department of Information Technology`,
    description2 : `K. J. Somaiya Institute Of Technology, Sion, Mumbai`,
    image: "assets/images/mayureshNivatkarPic.jpeg",
    linkedin : "https://www.linkedin.com/in/mayuresh-nivatkar-abbb261a4/",
    email: "mailto:mayur20010921@gmail.com"
  },
];



export default function DevelopersContact() {
  const history = useHistory();
  const back = () => {
    history.goBack();
  };
  // const focus = () => {};
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="xl">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{ color: "white" }}
              gutterBottom
            >
              <b>
              K. J. Somaiya Institute Of Technology , Sion, Mumbai
              </b>
              <br>
              </br>
            </Typography>
            <Typography align="center" style={{ color: "white" }}>
              <b>
                An Autonomous Institute Permanently Affiliated to the University of Mumbai<br/>
                Accredited by NAAC with ‘A’ Grade (3.21 CGPA)<br/> Approved by AICTE, New Delhi
              </b>
            </Typography>
            <br/>
            <br/>
            <Typography
            component="h3"
            variant="h5"
            align="center"
            style={{color: "white"}}>
              <b>
              Team - Mentors and Developers
              </b>
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={back}>
                    Go Back
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="md">
          <Paper style={{ padding: "5%" }} elevation={5}>
            {/* <Typography
              component="h1"
              variant="h6"
              align="center"
              color="textPrimary"
              gutterBottom
              style={{
                paddingTop: "2%",
                paddingBottom: "5%",
                textAlign: "center",
              }}
            >
              Mentors
            </Typography> */}

            <Grid container spacing={2}>
                  <Grid item  xs={12} sm={12} md={6} lg={6}>
                    <Card className={classes.card}>
                      <CardMedia
                        style={styles.media}
                        className={classes.cardMedia}
                        image='assets/images/PrincipalSir.png'
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom style={{ fontSize: "1.2em" }}>
                          <b>Dr. Suresh Ukarande</b>
                        </Typography>
                        <Typography style={{ fontSize: "0.9em" }}>
                          Principal,<br />
                          K. J. Somaiya Institute Of Technology, Sion, Mumbai
                        </Typography>
                        <br />
                        <Typography style={{ fontSize: "0.9em" }}>
                        Associate Dean - Faculty of Science and Technology,<br />
                        University of Mumbai
                        </Typography>
                        <br />
                        <Typography style={{ fontSize: "0.9em" }}>
                        National Executive Council Member,<br />
                        Indian Society for Technical Education, New Delhi
                        </Typography>
                       
                      </CardContent>
                      <CardActions>
                        <Button color="primary" align="center" target="_blank" href="https://www.linkedin.com/in/dr-suresh-ukarande/" >
                          <LinkedInIcon />
                        </Button>
                        <Button color="primary" align="center" target="_blank" href="mailto:principal.tech@somaiya.edu">
                          <EmailIcon />
                        </Button>
                        <Button color="primary" align="center" target="_blank" href="mailto:deanfotuom@gmail.com">
                          <EmailIcon />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item  xs={12} sm={12} md={6} lg={6}>
                    <Card className={classes.card}>
                      <CardMedia
                        style={styles.media}
                        className={classes.cardMedia}
                        image='assets/images/DrRadhikaKotecha.jpg'
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom style={{ fontSize: "1.2em" }}>
                          <b>Dr. Radhika Kotecha</b>
                        </Typography>
                        <Typography style={{ fontSize: "0.9em" }}>
                          Head - Department of Information Technology,<br />
                          K. J. Somaiya Institute Of Technology, Sion, Mumbai
                        </Typography>
                        <br />
                        <Typography style={{ fontSize: "0.9em" }}>
                        Innovation Ambassador,<br />
                        MoE’s Innovation Cell
                        </Typography>
                        <br />
                        
                       
                      </CardContent>
                      <CardActions>
                        <Button color="primary" align="center" target="_blank" href="https://www.linkedin.com/in/dr-radhika-kotecha/" >
                          <LinkedInIcon />
                        </Button>
                        <Button color="primary" align="center" target="_blank" href="mailto:radhika.kotecha@somaiya.edu">
                          <EmailIcon />
                        </Button>
                        <Button color="primary" align="center" target="_blank" href="mailto:kotecha.radhika7@gmail.com">
                          <EmailIcon />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                
              </Grid>

            <Divider />
            
            <div id="Team" style={{paddingTop : '60px'}}>
              {/* <Typography
                component="h1"
                variant="h6"
                align="center"
                color="textPrimary"
                gutterBottom
                style={{
                  paddingTop: "7%",
                  paddingBottom: "2%",
                  textAlign: "center",
                }}
              >
                Developed by
              </Typography> */}
              {/* End hero unit */}
              <Grid container spacing={2}>
                {cards.map((card, index) => (
                  <Grid item key={index} xs={12} sm={12} md={4} lg={4}>
                    <Card className={classes.card}>
                      <CardMedia
                        style={styles.media}
                        className={classes.cardMedia}
                        image={card.image}
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom style={{ fontSize: "1.2em" }}>
                          <b>{card.name}</b>
                        </Typography>
                        <Typography style={{ fontSize: "0.9em" }}>
                          {card.description1}
                        </Typography>
                        <Typography style={{ fontSize: "0.9em" }}>
                          {card.description2}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button color="primary" align="center" target="_blank" href={card.linkedin}>
                          <LinkedInIcon />
                        </Button>
                        <Button color="primary" align="center" target="_blank" href={card.email}>
                          <EmailIcon />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              
            </div>
          </Paper>
        </Container>
      </main>
    </React.Fragment>
  );
}
